import React from "react";
import { graphql } from "gatsby";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage } from "gatsby-plugin-image";
import Nav from "../components/nav";
import Header from "../components/header";
import Share from "../components/share";

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p className="align-center">{children}</p>;

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="font-semibold">{text}</span>,
    [MARKS.ITALIC]: (text) => <span className="italic">{text}</span>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p className="mt-5">{children}</p>;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <a className="pink-link" href={node.data.uri}>
          {children}
        </a>
      );
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return <span className="text-3xl font-semibold mt-5">{children}</span>;
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <span className="text-2xl font-semibold mt-5">{children}</span>;
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return <span className="text-xl font-semibold mt-5">{children}</span>;
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return <span className="text-lg font-semibold mt-5">{children}</span>;
    },
    [BLOCKS.HEADING_5]: (node, children) => {
      return <span className="text-md font-semibold mt-5">{children}</span>;
    },
    [BLOCKS.HEADING_6]: (node, children) => {
      return <span className="text-sm font-semibold mt-5">{children}</span>;
    },
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="list-disc ml-10 leading-4 mb-4">{children}</ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className="list-decimal ml-10 leading-4 mb-4">{children}</ol>
    ),
    //[INLINES.Heading3]: (node, children) => <span className="font-xl">{children}</span>,

    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      return (
        <GatsbyImage
          image={node.data.target.gatsbyImageData}
          className="md:w-full w-full md:max-w-md max-w-sm pb-4 my-10"
          alt={node.data.target.title}
        />
      );
    },
  },
};

const Post = ({ location, data }) => {
  const { title, author, createdAt, post, titleImage } = data.contentfulBlog;
  const btn1 = { colour: "outline", text: "See other posts", link: "/blog" };
  const header_button = [btn1];
  return (
    <main className="bg-abx-light-grey">
      <title>Alchemy Box - {title}</title>
      <Nav colour="grey" default_state="blog" />
      <Header
        title={title}
        logoText="Blog"
        colour="pink"
        buttons={header_button}
        padding_bottom={8}
        image={titleImage}
        share={true}
      />

      <div className="mx-8 md:mx-16 px-5 font-poppins text-abx-dark-grey pb-16 leading-8 text-justify">
        {renderRichText(post, options)}
        <div className="mt-16">
          <p>{author}</p>
          <p>{createdAt}</p>
        </div>
        <Share current_page={location.href} />
      </div>
    </main>
  );
};

export default Post;

export const pageQuery = graphql`
  query ($id: String) {
    contentfulBlog(id: { eq: $id }) {
      title
      id
      contentful_id
      description
      titleImage {
        title
        gatsbyImageData
      }
      post {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            title
            contentful_id
            gatsbyImageData
          }
        }
      }
    }
  }
`;
